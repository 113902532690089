<template>
  <v-container >
    <v-sheet class="mx-auto mt-3" max-width="1264" v-if="cart.orden.length > 0">
      <p class="headline font-weight-medium pediidos--text mb-5 ">{{$t('cart.summary_title')}}</p>
      <v-sheet class="mx-auto " max-width="1264" >
        <v-row >
          <v-col class="pb-0 " >
            <v-card class="ma-0" min-height="170" elevation="4"  >
              <v-list-item >
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">{{$t('cart.totals')}}</v-list-item-title>
                  <v-list-item-subtitle class="subtitle-1" ><b>{{$t('cart.sellers')}}:</b> {{cart.orden.length}}</v-list-item-subtitle>
                  <v-list-item-subtitle class="subtitle-1" ><b>{{$t('cart.articles')}}:</b> {{cart.usuario.quanty}}</v-list-item-subtitle>
                  <v-list-item-subtitle class="subtitle-1" ><b>{{$t('cart.subtotal')}}:</b> {{cart.usuario.subtotal | formatCurrency}}</v-list-item-subtitle>
                  <v-list-item-subtitle class="subtitle-1" ><b>{{$t("cart.shipments")}}:</b> {{(cart.usuario.total-cart.usuario.subtotal) | formatCurrency}}</v-list-item-subtitle>
                  <v-list-item-subtitle class="subtitle-1" ><b>{{$t('cart.total')}}:</b> {{cart.usuario.total | formatCurrency}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col class="pb-0 " >
            <v-card class="ma-0" min-height="170" elevation="4" >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">{{$t('shipping_data')}}</v-list-item-title>
                  <v-card-text class="text--secondary subtitle-1 ma-0 pa-0"><b>{{$t('address')}}:</b> {{user.address}}</v-card-text>
                  <v-list-item-subtitle class="subtitle-1" ><b>{{$t('alias')}}:</b> {{user.address_alias}}</v-list-item-subtitle>
                  <v-list-item-subtitle class="mb-2 subtitle-1" ><b>{{$t('city')}}:</b> {{user.city}}</v-list-item-subtitle>
                  <!-- TODO: AGREGAR PARA VARIABLES DE EMPRESA-->
                  <!-- <EditAddress id_map="map-checkout" ></EditAddress> -->
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
      <p class="headline font-weight-medium pediidos--text mt-10 mb-5 ">{{$t('cart.order_details')}}</p>
      <v-card class="ma-0" min-height="120" elevation="4" >
        <v-list >
          <v-list-group color="pediidos" v-for=" item in cart.orden" :key="item.shop" no-action>
            <template  v-slot:activator>
              <v-list-item-avatar>
                <v-img :src="item.shop_picture"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title><b>{{item.shop_name}}</b></v-list-item-title>
                <v-list-item-title class="subtitle-1" >{{item.cant_total_products}} {{$t('cart.articles')}}.
                  <b v-bind:class="{ 'error--text': (item.subtotal-item.order_min_price) < 0 }" >{{item.subtotal | formatCurrency}}</b>
                </v-list-item-title>
                <v-list-item-title class="subtitle-2" v-bind:class="{ 'error--text': (item.subtotal-item.order_min_price) < 0 }">{{$t('cart.order_min_price')}}: <b>{{item.order_min_price  | formatCurrency}}</b></v-list-item-title>
                <v-list-item-title class="subtitle-2">{{$t('cart.delivery_cost')}}: <b>{{item.delivery_cost | formatCurrency}}</b></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-divider ></v-divider>
            <v-list-item three-line class="px-5" v-for="child in item.products" :key="child.product_id">
              <v-list-item-avatar>
                <v-img :src="child.picture"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-card-text class="subtitle-2 text-uppercase text-ellipsis pa-0" v-text="child.name"></v-card-text>
                <v-list-item-subtitle>({{child.price | formatCurrency}}) {{$t('cart.units')}} {{child.cant}}</v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-2" >{{$t("cart.total")}}: {{ child | calcTotal}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>
      <v-sheet class="mx-auto pt-3 " max-width="1264" >
        <v-row >
          <v-col class="pb-0 " cols="12" md="6">
            <v-card class="ma-0" min-height="150" elevation="4"  >
              <v-list-item >
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">{{$t('cart.method_pay')}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list two-line max-height="255"  >
                <template v-for=" (item, index) in cart.orden" >
                  <v-list-item :key="item.shop" >
                    <v-list-item-avatar class="my-1">
                      <v-img :src="item.shop_picture"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content >
                      <v-list-item-title><b>{{item.shop_name}}</b></v-list-item-title>
                      <v-list-item-title v-if="item.method_pay === null" class="error--text" >{{$t('not_selected')}}</v-list-item-title>
                      <v-list-item-title v-else class="pediidos--text" >{{item.method_pay}}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu rounded="b-lg" bottom offset-y max-width="250" transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn outlined rounded small color="pediidos" class="mt-2" v-on="on" v-bind="attrs" >{{$t('change')}}</v-btn>
                        </template>
                        <v-list >
                          <template v-for=" (mp,index) in getMethodsPay(item.shop)">
                            <v-list-item v-if="mp.status" @click="setMethodPay(item.shop,mp.method_pay.name)" :key="index" >
                              <v-list-item-title >{{mp.method_pay.name}}</v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="index < cart.orden.length - 1" :key="index"></v-divider>
                </template>
              </v-list>
            </v-card>
          </v-col>
          <v-col class="pb-0 " v-if="allow_comment" >
            <v-card class="ma-0" min-height="150" elevation="4" >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">{{$t('comments')}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list >
                <v-list-group style="min-height:64px;"  color="pediidos" v-for=" item in cart.orden" :key="item.shop" no-action>
                  <template  v-slot:activator>
                    <v-list-item-avatar>
                      <v-img :src="item.shop_picture"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title><b>{{item.shop_name}}</b></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-divider ></v-divider>
                  <v-list-item class="px-5">
                    <v-text-field
                      class="mt-5"
                      @keyup="setComment($event,item.shop)"
                      append-icon="mdi-comment-text-outline"
                      :label="comment_label"
                      :placeholder="comment_label"
                      maxlength="50"
                      counter="50"
                      outlined
                      color="pediidos">
                    </v-text-field>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
      <v-btn class="mt-10 mb-6" rounded block dark x-large color="success" @click="checkOrder()" >{{$t('cart.make_order')}}</v-btn>
    </v-sheet >
    <v-sheet v-else class="text-center align-center justify-center " >
      <v-icon size="250" style="margin-top: 80px;" >mdi-cart</v-icon>
      <p class=" my-5" >{{$t('cart.msg_empty_cart')}}</p>
      <v-btn outlined large rounded color="pediidos" @click="goShoping()" >{{$t('cart.go_to_shoping')}}</v-btn>
    </v-sheet>
  </v-container>
</template>

<script type="text/javascript">
  import EditAddress from '@/components/EditAddress.vue';
  import Api from '@/helpers/api.js';
  import Utils from '@/helpers/Utils.js';
  import * as constants from '@/constants';
  import { mapMutations, mapState } from 'vuex';
  export default {
    name: 'checkout',
    components: { EditAddress },
    data() {
      return {
        comment_label: this.$t('comment'),
        MethodsPay:[],
        allow_comment: constants.ALLOW_CHECKOUT_COMMENT,
      }
    },
    filters:{
      formatCurrency(currency) {
        return Utils.formatCurrency(currency);
      },
      calcTotal(product){
        let total = (parseFloat(product.price) * parseInt(product.cant));
        return Utils.formatCurrency(String(total));
      },
    },
    async mounted(){
      for(const o of this.cart.orden){
        o.method_pay = null;
        this.getMethodsPayServer(o.shop);
      }
    },
    methods: {
      ...mapMutations(['APP_LOADER','SET_PRODUCTS','CLEAR_CART',]),
      getMethodsPay(shop_id){
        for(const mp of this.MethodsPay){
          if(mp.shop == shop_id){
            return mp.pays;
          }
        }
      },
      getMethodsPayServer(shop_id){
        this.APP_LOADER(true);
        let data = {shop_id: shop_id}
        Api.post('shopkeepers/shop/payment/methods/',data).then((result)=> {
          if(result.status < 300) {
            let pays = {'shop':shop_id,'pays':result.data};
            this.MethodsPay.push(pays);
            this.setMethodPayDefault(shop_id, result.data)
          }
        }).catch((error)=> {
          console.log('This is error ',error);
        })
        setTimeout(()=>{ this.APP_LOADER(false); }, 500);
      },
      setMethodPayDefault(shop_id,data){
        for(const m of data){
          if(m.method_default === true){
            for(const o of this.cart.orden){
              if(o.shop === shop_id){
                  this.setMethodPay(shop_id, m.method_pay.name);
              }
            }
          }
        }
      },
      setComment(event, shop_id){
        for(const o of this.cart.orden){
          if(o.shop === shop_id){
            o.comment = event.target.value;
          }
        }
      },
      setMethodPay(shop_id, method){
        for(const o of this.cart.orden){
          if(o.shop === shop_id){
            o.method_pay = method;
          }
        }
      },
      checkOrder(){
        let error_text = "";
        for(const o of this.cart.orden){
          if(o.method_pay === null){
            error_text = error_text + "<li>"+this.$t('cart.method_pay_error')+o.shop_name+".</li>"
          }
          if( (o.subtotal-o.order_min_price) < 0 ) {
            error_text = error_text + "<li>"+this.$t('cart.order_min_price_error')+o.shop_name+ ".</li>"
          }
        }
        if(error_text.length > 0){
          this.myNotify('warn',this.$t('_warning'),"<lu>"+error_text+"</lu>");
        }else{
          this.sendOrder();
        }
      },
      async sendOrder(){
        this.APP_LOADER(true);
        Api.post('orders/create/',this.cart).then((result)=> {
          if(result.status < 300) {
            this.clearOrder();
            this.myNotify('success',this.$t('_success'),this.$t('cart.order_success'));
          }else{
            this.myNotify('error',this.$t('_error'),this.$t('error.generic'));
          }
        }).catch((error)=> {
          console.log('This is error ',error);
        }).finally(()=>{
          setTimeout(()=>{ this.APP_LOADER(false); }, 500);
        })
      },
      clearOrder(){
        this.CLEAR_CART({'user_id':this.user.user_id,'address_id':this.user.address_id});
        this.products.forEach(function(p){p.quanty = 0});
        this.goShoping();
      },
      goShoping(){
        this.$router.push('/home');
      },
    },
    computed: {
      ...mapState(['appLoader','user','cart','products']),
    },
  }
</script>

<style type="text/css" scoped>
</style>